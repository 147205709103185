import React from 'react'
import LegalMenu from '../components/legalMenu'
import Meta from '../components/layout/meta'
import { Layout } from '../components/layout'
import { Link } from '../components/UI'
import { LegalContent, StickyLegalMenu, TextContainer } from '../utils'
import config from '../config'

const PrivacyPage = () => (
    <Layout>
        <Meta title="Tellow - Privacy Statement" description="Tellow's privacy statement" path="/privacy" />
        <LegalContent>
            <StickyLegalMenu>
                <LegalMenu className="sticky" />
            </StickyLegalMenu>
            <TextContainer>
                <h1>Privacyverklaring</h1>
                <div className="col-lg-8 padding-top padding-bottom">
                    <p>
                        <strong>
                            Tellow B.V. (“Tellow”) verwerkt persoonsgegevens. Wij willen jou hierover graag duidelijk en transparant informeren. In dit privacy statement leggen wij
                            uit hoe Tellow omgaat met het verwerken van persoonsgegevens. Heb je vragen over dit privacy statement? Neem dan contact met ons op:
                        </strong>
                    </p>
                    <p>
                        Tellow B.V.
                        <address>
                            {config.address.street}
                            <br />
                            {config.address.postalcode}, {config.address.city} <br />
                            <a href="mailto:support@tellow.nl">support@tellow.nl</a>
                        </address>
                        <br />
                    </p>
                    <h2>
                        <strong>1. Definities</strong>
                    </h2>
                    <p>Hieronder vind je de definitie van de belangrijkste termen die we gebruiken in dit privacy statement.</p>
                    <table cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <h4>
                                        <strong>
                                            <i>Persoonsgegevens</i>
                                        </strong>
                                    </h4>
                                </td>
                                <td valign="top">
                                    Dit zijn gegevens die direct of indirect iets over jou zeggen. Bijvoorbeeld jouw naam en adres, maar ook jouw inkomen. Gegevens van een
                                    eenmanszaak, VOF of maatschap zijn ook persoonsgegevens. Gegevens van een rechtspersoon zijn geen persoonsgegevens. De gegevens van een
                                    contactpersoon of vertegenwoordiger van een rechtspersoon zijn dat wel.
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <strong>Verwerken</strong>
                                </td>
                                <td valign="top">
                                    Alles wat met persoonsgegevens kan worden gedaan. Bijvoorbeeld het verzamelen, maar ook het opslaan, gebruiken, doorgeven en verwijderen van
                                    gegevens.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>2. Van wie verwerkt Tellow persoonsgegevens?</h2>
                    <p>
                        Wij verwerken persoonsgegevens van mensen met wie wij direct of indirect een relatie hebben, willen krijgen of hebben gehad. Dat zijn dus bijvoorbeeld
                        persoonsgegevens van gebruikers en hun vertegenwoordigers en mensen die interesse tonen in onze producten en diensten.
                    </p>
                    <h2>3. Wat verwacht Tellow van bedrijven en organisaties?</h2>
                    <p>
                        Geeft jouw bedrijf of organisatie persoonsgegevens van medewerkers of Uiteindelijk Belanghebbende (UBO) aan ons door? Dan verwachten wij dat je ook jouw
                        medewerkers, bestuurders of UBO hierover informeert. Je kunt dit privacy statement aan hen geven. Zij kunnen dan zien hoe wij met hun persoonsgegevens
                        omgaan.
                    </p>
                    <h2>4. Wie is verantwoordelijk voor de verwerking van jouw persoonsgegevens?</h2>
                    <p>
                        Dit privacy statement gaat over verwerkingen van persoonsgegevens door Tellow. Tellow maakt deel uit van Ageras. Als de wet ons dat toestaat, kunnen
                        gegevens worden uitgewisseld binnen Ageras. Waar we in dit privacy statement spreken over Ageras, bedoelen we de volledige{' '}
                        <a href="https://nl.ageras.com/" target="_blank" rel="noreferrer">
                            Ageras
                        </a>{' '}
                        groep. We houden ons daarbij aan de spelregels die we binnen de groep hebben afgesproken.
                    </p>
                    <p>Doet, deed of gaat u zaken doen met Tellow? Dan is Tellow verantwoordelijk als verwerker van uw persoonsgegevens.</p>
                    <h2>
                        <strong>5. Welke persoonsgegevens verwerkt Tellow?</strong>
                    </h2>
                    <p>Tellow verwerkt verschillende soorten persoonsgegevens. Hieronder een overzicht.</p>
                    <table className="gegevens">
                        <tbody>
                            <tr>
                                <td className="top" valign="top">
                                    <strong>Soorten gegevens</strong>
                                </td>
                                <td className="top" valign="top">
                                    <strong>Wat voor een gegevens kunnen dit zijn?</strong>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">Direct of indirect identificerende gegevens</td>
                                <td valign="top">Naam, adres, telefoonnummer, e-mailadres, gegevens die op uw identiteitsbewijs staan.</td>
                            </tr>
                            <tr>
                                <td valign="top">Locatiegegevens</td>
                                <td valign="top">Gegevens waaruit blijkt waar u zich bevindt.</td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens over en voor overeenkomsten</td>
                                <td valign="top">De rechtsvorm of SBI-code van je bedrijf.</td>
                            </tr>
                            <tr>
                                <td valign="top">Betaal- en transactiegegevens</td>
                                <td valign="top">
                                    Gegevens van degene aan wie je hebt betaald of van wie je een betaling hebt ontvangen, wanneer een betaling heeft plaatsgevonden en wat het
                                    saldo is op je rekening.
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">Bijzondere persoonsgegevens</td>
                                <td valign="top">Biometrische gegevens, of KvK nummer.</td>
                            </tr>
                            <tr>
                                <td valign="top">E-mail en chatberichten</td>
                                <td valign="top">Vragen en/of opmerkingen voor support van Tellow.</td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens over gebruik van de Tellow website en de app</td>
                                <td valign="top">IP adres, gegevens over het gedrag en apparaat waarmee u online diensten of onze website gebruikt.</td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens die wij van andere partijen ontvangen</td>
                                <td valign="top">Gegevens van de Kamer van Koophandel en partijen aan wie je toestemming hebt gegeven om data met Tellow te delen.</td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens die wij met andere partijen delen</td>
                                <td valign="top">
                                    Gegevens die wij verstrekken aan andere partijen die wij inschakelen om te helpen bij onze dienstverlening. Gegevens waarvan u ons heeft
                                    gevraagd die te delen met een andere partij.
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens t.b.v. jouw en onze veiligheid</td>
                                <td valign="top">IP-adres, cookies, gebruik van Tellow.</td>
                            </tr>
                            <tr>
                                <td valign="top">Gegevens uit afbeeldingen</td>
                                <td valign="top">Persoon en/of bedrijfsgegevens die vermeld zijn op facturen en/of bonnen.</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>
                        <strong>6. Hoe komt Tellow aan jouw persoonsgegevens?</strong>
                    </h2>
                    <p>
                        Wij ontvangen jouw gegevens omdat je deze zelf aan ons geeft, zoals wanneer je gegevens achterlaat op onze website om contact met je op te nemen. Andere
                        gegevens ontstaan door onze dienstverlening. Wij kunnen ook gegevens ontvangen van anderen. Bijvoorbeeld van leveranciers of andere partijen waar wij mee
                        samenwerken, of een partij die je toestemming hebt gegeven om gegevens met ons te delen.
                    </p>
                    <h2>
                        <strong>7. Waarom verwerkt Tellow persoonsgegevens?</strong>
                    </h2>
                    <p>
                        Hiervoor staat welke persoonsgegevens Tellow verwerkt. Hierna staat voor welke doelen wij persoonsgegevens verwerken. Ook staat de grondslag voor verwerking
                        hierna vermeld. Voor iedere verwerking van persoonsgegevens is volgens de wet een grondslag nodig. Daarnaast moeten wij op basis van onze zorgplicht
                        persoonsgegevens verwerken als daar aanleiding toe is.
                    </p>
                    <p>Hieronder vind je een uitgebreide toelichting op de redenen om jouw gegevens te verwerken:</p>
                    <ol>
                        <li>
                            Aangaan van een relatie / overeenkomst. Tellow heeft persoonsgegevens van je nodig als je een (nieuwe) dienst van ons wilt gaan gebruiken of als je
                            contact met ons opneemt. Deze gegevens ontvangen wij van de bank waar je jouw rekening-courant of spaarrekening hebt. De wettelijke grondslagen hiervoor
                            zijn een wettelijke verplichting, gerechtvaardigd belang bij het verwerken van jouw gegevens voor deze doelen of omdat dit noodzakelijk is voor het
                            sluiten of uitvoeren van de overeenkomst.
                        </li>
                        <li>
                            Uitvoeren van overeenkomsten en opdrachten. Als je eenmaal gebruiker bent van Tellow, willen we graag onze afspraken nakomen en onze dienstverlening
                            goed uitvoeren. Daarvoor hebben we ook jouw gegevens nodig, zoals je naam en adresgegevens. We moeten bijvoorbeeld je identiteit kunnen vaststellen
                            voordat we jou toegang geven tot jouw gegevens. De wettelijke grondslagen hiervoor zijn dat we jouw gegevens verwerken omdat nodig is om de overeenkomst
                            die we hebben gesloten, uit te voeren. Maar ook het hebben van een wettelijke verplichting. Of het hebben van een gerechtvaardigd belang om jouw
                            gegevens te verwerken.
                        </li>
                        <li>
                            Nakomen van contracten met derden. Als Tellow een leverancier inschakelt om bepaalde taken uit te voeren, bijvoorbeeld het uitlezen van bonnetjes, dan
                            moeten we jouw gegevens doorgeven. We hebben altijd contractueel vastgelegd dat deze data strikt vertrouwelijk blijft. De wettelijke grondslag hiervoor
                            is dat we jouw gegevens nodig hebben om de overeenkomst die wij hebben gesloten uit te voeren of omdat wij dat wettelijk verplicht zijn of omdat wij
                            daar een gerechtvaardigd belang bij hebben.
                        </li>
                        <li>
                            Nakomen van wettelijke verplichtingen. Politie, justitie, belastingdienst en toezichthouders kunnen gegevens bij ons opvragen. Tellow moet dan gegevens
                            over jou doorgeven. De wettelijke grondslag hiervoor is een wettelijke verplichting of omdat wij anders geen uitvoering mogen geven aan een overeenkomst
                            met jou. Of het hebben van een gerechtvaardigd belang om jouw gegevens te verwerken om aan een wettelijke of andere juridische verplichting te kunnen
                            voldoen.
                        </li>
                        <li>
                            Uitvoeren van belangrijke management- of bedrijfsprocessen. Tellow gebruikt jouw gegevens voor belangrijke processen, zoals risico analyses en audits.
                            De wettelijke grondslag hiervoor is een wettelijke verplichting of omdat wij een gerechtvaardigd belang hebben. Ook kan de verwerking van jouw gegevens
                            noodzakelijk zijn voor de uitvoering van onze overeenkomst met jou.
                        </li>
                        <li>
                            Beschermen van jouw en onze data. Belangrijk onderdeel hiervan is het bestrijden van fraude of cyber-criminaliteit. Voor dit doel kunnen we ook openbare
                            bronnen raadplegen, zoals openbare registers, kranten en het internet. De wettelijke grondslag hiervoor is een wettelijke verplichting of het
                            gerechtvaardigd belang van Tellow of onze klanten en medewerkers.
                        </li>
                        <li>
                            Ontwikkelen en verbeteren van diensten. In sommige gevallen verwerkt Tellow hiervoor persoonsgegevens, bijvoorbeeld als we een vraag van een gebruiker
                            onderzoeken. We analyseren daarnaast het gedrag van (potentiële) gebruikers door middel van cookies en vergelijkbare technieken op onze websites. Om de
                            kwaliteit van onze dienstverlening te verbeteren kunnen we ook opnamen vastleggen, bijvoorbeeld van telefoongesprekken en chatsessies. Als dit laatste
                            het geval is, zullen we je hierover duidelijk vooraf informeren. We kunnen je ook vragen om feedback. Uiteraard is het niet verplicht om daaraan mee te
                            werken. De wettelijke grondslag hierbij is het hebben van een gerechtvaardigd belang. Wij kunnen jou ook om toestemming vragen om jouw gegevens voor de
                            ontwikkeling en verbetering van onze producten en diensten te verwerken. Geef je geen toestemming voor het ontwikkelen en verbeteren van onze producten
                            en diensten? Dan heeft dat geen gevolgen voor onze dienstverlening aan jou. Je kunt jouw gegeven toestemming altijd intrekken via{' '}
                            <a href="mailto:support@tellow.nl">support@tellow.nl</a> of via de chat-functie van Tellow.
                        </li>
                        <li>
                            Marketing en relatiebeheer. We kunnen je persoonsgegevens verwerken voor promotie- of marketingdoeleinden. Als je je daarvoor hebt aangemeld, sturen we
                            je een nieuwsbrief. Hiervoor kun je je afmelden. Gaan wij andere manieren van Direct Marketing gebruiken in de toekomst? Dan zullen we je daarover
                            informeren en je de mogelijkheid geven om je voor Direct Marketing of benadering via een specifiek kanaal af te melden. De wettelijke grondslag hierbij
                            is een gerechtvaardigd belang.
                        </li>
                    </ol>
                    <h2>
                        <strong>8. Hoe lang bewaart Tellow jouw persoonsgegevens?</strong>
                    </h2>
                    <p>
                        Wij bewaren jouw gegevens niet langer dan wij deze nodig hebben voor de doelen waarvoor wij ze hebben verzameld of de doelen waarvoor wij deze hergebruiken.
                        Tellow hanteert een bewaarbeleid. Daarin is vastgesteld hoe lang we gegevens bewaren. Tellow bewaart in de meeste gevallen alle gegevens dertien maanden na
                        het einde van de overeenkomst of jouw relatie met Tellow. Het kan ook voorkomen dat we jouw gegevens korter of langer bewaren, bijvoorbeeld als we een
                        klacht behandelen.
                    </p>
                    <p>
                        Als wij de gegevens niet langer nodig hebben voor de doelen zoals omschreven in hoofdstuk 7 kunnen wij de gegevens wel nog bewaren voor archivering, bij
                        juridische procedures of voor historische of wetenschappelijke onderzoek of statistische doeleinden.
                    </p>
                    <h2>
                        <strong>9. Verwerkt Tellow ook bijzondere persoonsgegevens?</strong>
                    </h2>
                    <p>
                        Bijzondere persoonsgegevens, strafrechtelijke gegevens en het BSN zijn gevoelige gegevens. Bijzondere persoonsgegevens zijn bijvoorbeeld gegevens over
                        gezondheid, biometrische gegevens, etnische gegevens of gegevens over ras. Wij verwerken deze gegevens alleen als dat noodzakelijk is.
                    </p>
                    <p>Jouw BSN gebruiken wij alleen als dat wettelijk is toegestaan.</p>
                    <p>
                        We verwerken ook bijzondere persoonsgegevens in het betalingsverkeer, bijvoorbeeld als Tellow een bon verwerkt van een betaling bij een apotheek of een
                        overboeking naar een politieke partij. Hieruit kunnen gegevens over jouw gezondheid of jouw politieke voorkeur worden afgeleid.
                    </p>
                    <p>
                        Verder verwerken wij bijzondere persoonsgegevens als dat wettelijk toegestaan is, omdat je deze gegevens zelf openbaar hebt gemaakt of met jouw toestemming.
                        Geef jij ons toestemming om bijzondere persoonsgegevens over jou vast te leggen? Of maak je deze gegevens zelf openbaar? Ook dan verwerken wij deze gegevens
                        alleen als dit noodzakelijk is voor onze dienstverlening.
                    </p>
                    <p>Heb je ons toestemming gegeven om bijzondere persoonsgegevens vast te leggen? Dan kunt je die toestemming altijd intrekken via support@tellow.nl.</p>
                    <h2>
                        <strong>10. Wie heeft binnen Tellow toegang tot jouw gegevens?</strong>
                    </h2>
                    <p>
                        Binnen Tellow hebben alleen personen toegang tot jouw persoonsgegevens die deze gelet op hun functie nodig hebben. Al deze personen hebben een
                        geheimhoudingsplicht.
                    </p>
                    <h2>
                        <strong>11. Gebruikt Tellow persoonsgegevens ook voor andere doelen?</strong>
                    </h2>
                    <p>
                        Willen wij gegevens voor een ander doel gebruiken dan waarvoor wij ze gekregen hebben zijn? Dan mogen wij dat wanneer er tussen beide doelen een nauwe
                        samenhang bestaat en wanneer dit past bij het doel waarvoor de gegevens zijn verzameld. Is er onvoldoende samenhang tussen het doel waarvoor wij de gegevens
                        hebben verkregen en het nieuwe doel? Dan vragen wij jou om toestemming.
                    </p>
                    <h2>
                        <strong>12. Neemt Tellow automatische besluiten over mij? </strong>
                    </h2>
                    <p>
                        Automatische besluiten zijn beslissingen over jou die gemaakt worden door computers, en niet (meer) door mensen. Tellow mag van de wet gebruik maken van
                        geautomatiseerde beslissingen inclusief profilering. Maar daar gelden wel bepaalde regels voor. Op dit moment maakt Tellow geen gebruik van volledig
                        automatische besluitvorming. Gaan wij dit wel doen, dan wordt je hierover geïnformeerd.
                    </p>
                    <h2>
                        <strong>13. Geeft Tellow jouw persoonsgegevens aan anderen door?</strong>
                    </h2>
                    <p>
                        Er wordt géén informatie over jouw boekhouding doorgegeven aan een bank, tenzij je daar zelf expliciet toestemming voor geeft. Jouw gegevens worden
                        doorgegeven aan andere partijen als wij daar wettelijk toe verplicht zijn, omdat wij een overeenkomst met jou moeten uitvoeren of omdat wij een andere
                        dienstverlener inzetten. Wij geven ook gegevens door als dat nodig is om de afspraken met jou na te komen. Soms kun je ook zelf het initiatief nemen om jouw
                        gegevens door te geven aan een andere partij.
                    </p>
                    <p>
                        Soms schakelen wij andere partijen/zakelijke partners in die in onze opdracht persoonsgegevens bewerken. Tellow beoordeelt wel eerst of deze partijen
                        voldoende betrouwbaar zijn. Wij kunnen alleen andere partijen inschakelen als dit past bij het doel waarvoor wij jouw persoonsgegevens hebben verwerkt.
                        Daarnaast kan deze andere partij alleen onze opdracht krijgen als hij bepaalde afspraken met ons maakt en aantoonbaar passende beveiligingsmaatregelen heeft
                        genomen en geheimhouding garandeert. Het gaat bij Tellow om de volgende partijen: Amazon Web Services, Google Cloud Platform, ASU Advies &amp;
                        Administratie, Intercom, Docraptor, Apple, Stripe, Zapier, Baremetrics, Amplitude, Segment, Aiia, Plaid en Klippa. Daarnaast gebruikt Tellow een aantal
                        cookies, die staan beschreven in het <Link to="/cookies/">cookiebeleid</Link>.
                    </p>
                    <p>
                        Als wij jouw gegevens zelf doorgeven naar andere partijen buiten de Europese Unie, nemen wij extra maatregelen om jouw gegevens te beschermen. Niet in alle
                        landen buiten de Europese Unie gelden dezelfde regels om jouw gegevens te beschermen zoals dat binnen Europa verplicht is. Gebruiken wij derde partijen
                        buiten de EU? En biedt het land waar deze partij zich bevindt volgens de Europese Commissie onvoldoende bescherming bij de verwerking van persoonsgegevens?
                        Dan geven wij alleen persoonsgegevens door als er andere passende waarborgen zijn, zoals door de Europese Commissie goedgekeurde contractuele afspraken of
                        op basis van het ‘Privacy Shield’ (Verenigde Staten).
                    </p>
                    <h2>
                        <strong>14. Welke rechten heb je bij Tellow?</strong>
                    </h2>
                    <p>
                        Wanneer we jouw persoonsgegevens verwerken, dan heb je bepaalde rechten met betrekking tot deze gegevens. Als je gebruik wilt maken van deze rechten kan je
                        een e-mail sturen naar support@tellow.nl.
                    </p>
                    <ol>
                        <li>
                            Recht op informatie. Tellow informeert je over de persoonsgegevens die we verwerken en waarom, onder andere via dit privacy statement. Je kunt ook
                            altijd een vraag stellen via support@tellow.nl!
                        </li>
                        <li>
                            Recht op inzage en rectificatie. We kunnen je op verzoek inzage geven in de gegevens die wij van jou hebben verwerkt. Vind je dat jouw persoonsgegevens
                            onjuist of onvolledig zijn verwerkt? Dan kun je ons vragen om de gegevens te wijzigen of aan te vullen (rectificatie).
                        </li>
                        <li>Recht op gegevenswissing. Je kunt ons vragen gegevens die Tellow heeft vastgelegd te wissen.</li>
                        <li>
                            Recht op beperking. Je kunt ons vragen de persoonsgegevens die wij van jou verwerken te beperken. Dat betekent dat wij minder gegevens van jou
                            verwerken.
                        </li>
                        <li>
                            Recht op dataportabiliteit. Je hebt het recht ons te vragen om gegevens die je in het kader van een contract met ons of met jouw toestemming aan Tellow
                            hebt verstrekt in een gestructureerde en machine-leesbare vorm te verkrijgen of over te dragen naar een andere partij. Vraag je om gegevens rechtstreeks
                            over te dragen naar een andere partij? Dan kan dat alleen als dat technisch mogelijk is. Soms is het al mogelijk om gegevens die je zelf hebt verstrekt
                            zelf te verkrijgen. Bijvoorbeeld jouw transacties en boekingen: via onze online diensten kun je deze raadplegen.
                        </li>
                        <li>
                            Recht van bezwaar tegen verwerking op basis van een gerechtvaardigd belang. Verwerken wij jouw gegevens omdat wij daar een gerechtvaardigd belang bij
                            hebben? Bijvoorbeeld bij het maken van opnamen van telefoonopnamen zonder dat dat wettelijk verplicht is? Dan kun je daar bezwaar tegen maken. Wij
                            zullen dan een nieuwe afweging maken om te bepalen of jouw gegevens daar inderdaad niet meer voor gebruikt mogen worden. We stoppen de verwerking als
                            jouw belang zwaarder weegt dan ons belang. We laten jou gemotiveerd weten wat onze beslissing is.
                        </li>
                        <li>
                            Recht van bezwaar tegen Direct Marketing. Je hebt het recht ons te vragen jouw gegevens niet langer te gebruiken voor Direct Marketing. Het kan zijn dat
                            jouw bezwaar alleen ziet op benadering via een specifiek kanaal. Bijvoorbeeld als je niet meer telefonisch benaderd wil worden, maar nog wel onze
                            nieuwsbrieven wil ontvangen. Wij zullen er dan voor zorgen dat je niet langer wordt benaderd via dat betreffende kanaal.
                        </li>
                    </ol>
                    <p>
                        Heb je één van de hiervoor beschreven verzoeken aan ons gedaan? Dan streven wij ernaar zo’n verzoek binnen één week te beantwoorden. Wij antwoorden
                        uiterlijk binnen een maand. Dan vertellen we ook of we je verzoek kunnen en willen uitvoeren en onder welke voorwaarden en op welke termijn.
                    </p>
                    <h2>
                        <strong>15. Waar kun je terecht met een vraag of klacht?</strong>
                    </h2>
                    <p>
                        Voor vragen over de verwerking van persoonsgegevens door Tellow kun je mailen met&nbsp;
                        <a href="mailto:support@tellow.nl">
                            <strong>support@tellow.nl</strong>
                        </a>. 
                        Heb je een klacht over de verwerking van jouw persoonsgegevens door Tellow dan kun je ook terecht bij de{' '}
                        <a
                            href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/contactgegevens-algemeen"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong>Autoriteit Persoonsgegevens</strong>
                        </a>
                        .
                    </p>
                    <h2>
                        <strong>16. Kan Tellow dit privacy statement wijzigen?</strong>
                    </h2>
                    <p>
                        Ja, ons privacy statement kan van tijd tot tijd wijzigen. Als er nieuwe gegevensverwerkingen zijn, dan passen wij het privacy statement daarop aan. En als
                        deze wijzigingen ook voor jou van belang zijn, dan attenderen wij jou daarop of maken wij de wijzigingen op een opvallende manier aan jou kenbaar. De meest
                        actuele versie van ons privacy statement kun je steeds terugvinden op{' '}
                        <a href="https://www.tellow.nl" rel="home">
                            tellow.nl
                        </a>
                        .
                    </p>
                </div>
            </TextContainer>
        </LegalContent>
    </Layout>
)

export default PrivacyPage
